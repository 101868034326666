import store from "@/store/index";
import axios from "axios";
import router from "@/router";

const baseUrl = process.env.VUE_APP_BASE_URL;
export function getBaseUrl() {
  return baseUrl;
}

const CPS = axios.create({
  baseURL: baseUrl + "cps",
  headers: {
    "Content-Type": "application/json",
  },
});

const responseSuccessHandler = (response) => {
  return response;
};

const responseErrorHandler = (error) => {
  if (error.response.status === 401) {
    router.push("/").catch(() => {});
  }
  return Promise.reject(error);
};

CPS.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);
CPS.interceptors.request.use(function (config) {
  const token = store.state.authToken;
  config.headers.Authorization = token !== null ? `Bearer ${token}` : "";
  return config;
});

const Credit = axios.create({
  baseURL: baseUrl + "credit",
  headers: {
    "Content-Type": "application/json",
  },
});

Credit.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

Credit.interceptors.request.use(function (config) {
  const token = store.state.authToken;
  config.headers.Authorization = token !== null ? `Bearer ${token}` : "";
  return config;
});

const IO = axios.create({
  baseURL: baseUrl + "io",
  headers: {
    "Content-Type": "application/json",
  },
});

IO.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

IO.interceptors.request.use(function (config) {
  const token = store.state.authToken;
  config.headers.Authorization = token !== null ? `Bearer ${token}` : "";
  return config;
});

const APIauth = axios.create({
  baseURL: baseUrl + "identity",
  headers: {
    "Content-Type": "application/json",
  },
});

APIauth.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

APIauth.interceptors.request.use(function (config) {
  const token = store.state.authToken;
  config.headers.Authorization = token !== null ? `Bearer ${token}` : "";
  return config;
});

const Login = axios.create({
  baseURL: process.env.VUE_APP_LOGIN_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

Login.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

Login.interceptors.request.use(function (config) {
  const token = store.state.authToken;
  config.headers.Authorization = token !== null ? `Bearer ${token}` : "";
  return config;
});

const KYX = axios.create({
  baseURL: baseUrl + "kyx",
  headers: {
    "Content-Type": "application/json",
  },
});

KYX.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

KYX.interceptors.request.use(function (config) {
  const token = store.state.authToken;
  config.headers.Authorization = token !== null ? `Bearer ${token}` : "";
  return config;
});

const IPN = axios.create({
  baseURL: baseUrl + "hooks",
  headers: {
    "Content-Type": "application/json",
  },
});

IPN.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

IPN.interceptors.request.use(function (config) {
  const token = store.state.authToken;
  config.headers.Authorization = token !== null ? `Bearer ${token}` : "";
  return config;
});
const XFA = axios.create({
  baseURL: baseUrl + "xfa",
  headers: {
    "Content-Type": "application/json",
  },
});

XFA.interceptors.response.use(
    (response) => responseSuccessHandler(response),
    (error) => responseErrorHandler(error)
);

XFA.interceptors.request.use(function (config) {
  const token = store.state.authToken;
  config.headers.Authorization = token !== null ? `Bearer ${token}` : "";
  return config;
});


const SmsConf = axios.create({
  baseURL: baseUrl + "smsconf",
  headers: {
    "Content-Type": "application/json",
  },
});

SmsConf.interceptors.response.use(
    (response) => responseSuccessHandler(response),
    (error) => responseErrorHandler(error)
);

SmsConf.interceptors.request.use(function (config) {
  const token = store.state.authToken;
  config.headers.Authorization = token !== null ? `Bearer ${token}` : "";
  return config;
});

export default { CPS, APIauth, KYX, Credit, IO, Login, IPN,XFA,SmsConf };