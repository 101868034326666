const routes = [
    {
        path: "/approvals",
        name: "Approvals",
        component: () => import("@/components/hoAdmin/requests/Index"),
        meta: {
            title: "Tanda | Approvals",
            requiresAuth: true,
        },
        children: [
            {
                path: "/approvals/my-requests",
                name: "My Requests",
                component: () => import("@/components/hoAdmin/requests/MyRequests"),
                meta: {
                    title: "Tanda |  My Requests",
                    requiresAuth: true,
                },
            },
            {
                path: "/approvals/my-approvals",
                name: "My Approvals",
                component: () => import("@/components/hoAdmin/requests/MyApprovals"),
                meta: {
                    title: "Tanda | My Approvals",
                    requiresAuth: true,
                },
            },
            {
                path: "/approvals/pending-payments",
                name: "Pending Payments",
                component: () =>
                    import("@/components/hoAdmin/requests/PendingPayments"),
                meta: {
                    title: "Tanda | Payments",
                    requiresAuth: true,
                },
            },
            {
                path: "/approvals/payments",
                name: "Payments",
                component: () => import("@/components/hoAdmin/requests/Payments"),
                meta: {
                    title: "Tanda | Payments",
                    requiresAuth: true,
                },
            },
        ],
    },
];

export default routes;
