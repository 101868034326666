const routes = [
  {
    path: "/apps",
    name: "Applications",
    component: () => import("@/components/hoAdmin/apps/Apps"),
    meta: {
      title: "Tanda | Applications",
      requiresAuth: true,
    },
  },
];

export default routes;
