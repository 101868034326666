/*
 * Author: Okola Cetric - okolacetric@gmail.com Fri 1 Dec 2023
 */

const FADE_DUR = 700,
  MIN_DUR = 5000;
let toastContainer;

let Notification = class Notifications {
  constructor() {}

  notify(str, toastType) {
    let duration = Math.max(MIN_DUR, str.length * 80);
    if (!toastContainer) {
      toastContainer = document.createElement("div");
      toastContainer.classList.add("toastContainer");
      document.body.appendChild(toastContainer);
    }

    const success = `
 <div class="toast-content">
 <div class="close">&#10005;</div>
 <div class="icon" style="--stroke:#6ACACD">
<svg aria-hidden="true" focusable="false"  xmlns="http://www.w3.org/2000/svg" width="1.875em" height="1.875em" viewBox="0 0 30 30"> <circle fill="none" stroke-width="2" cx="50%" cy="50%" r="13" stroke-dasharray="100"> <animate attributeName="stroke-dashoffset" from="100" to="0" dur="0.9s" /> </circle> <polyline fill="none" stroke-width="2" stroke-linecap="round" points="8,17 13,21 22,10" stroke-dasharray="100"> <animate attributeName="stroke-dashoffset"  from="100" to="0" dur="4s" /> </polyline> </svg>
</div>
<div>
<p class="toast__type">Success</p>
 <p class="toast__message">${str}</p>
</div>
 <div class="progress active" style="--bg: #6ACACD"></div>
 </div>`;

    const warning = `
 <div class="toast-content">
 <div class="close">&#10005;</div>
 <div class="icon" style="--stroke:#F2711C">
 <svg aria-hidden="true" focusable="false"  xmlns="http://www.w3.org/2000/svg" width="1.875em" height="1.875em" viewBox="0 0 30 30" > <path  d="M 13 2 Q 15,0 17,2 L 26,23 Q 26,26 23,26 L 6,26 Q 2,26 3,22 L 13,2" stroke-width="2" fill="none" stroke-linecap="round" stroke-dasharray="100"> <animate attributeName="stroke-dashoffset"  from="100" to="0" dur="0.9s" /> </path> <line  fill="none" stroke-width="3" stroke-linecap="round" x1="15" y1="9" x2="15" y2="17" stroke-dasharray="100"> <animate attributeName="stroke-dashoffset"  from="100" to="0" dur="5s" /> </line> <line  fill="none" stroke-width="3" stroke-linecap="round" x1="15" y1="21" x2="15" y2="22" stroke-dasharray="100"> <animate attributeName="stroke-dashoffset"  from="100" to="0" dur="5s" /> </line> </svg>
</div>
<div>
<p class="toast__type">Warning</p>
 <p class="toast__message">${str}</p>
</div>
 <div class="progress active" style="--bg: #F2711C"></div>
 </div>`;

    const danger = `
      <div class="toast-content">
      <div class="close">&#10005;</div>
      <div class="icon" style="--stroke:#EC1558">
        <svg aria-hidden="true" focusable="false"  xmlns="http://www.w3.org/2000/svg" width="1.875em" height="1.875em" viewBox="0 0 30 30"> <circle fill="none"  stroke-width="2"  cx="50%" cy="50%" r="13" stroke-dasharray="100"> <animate attributeName="stroke-dashoffset" from="100" to="0" dur="0.9s" /> </circle> <line fill="none"  stroke-width="2" stroke-linecap="round"  x1="10.5" y1="10.5" x2="19.5" y2="19.5" stroke-dasharray="100"> <animate attributeName="stroke-dashoffset"  from="100" to="0" dur="4s" /> </line> <line fill="none" stroke-width="2" stroke-linecap="round"  x1="19.5" y1="10.5" x2="10.5" y2="19.5" stroke-dasharray="100"> <animate attributeName="stroke-dashoffset"  from="100" to="0" dur="4s" /> </line> </svg></div>
      <div>
        <p class="toast__type">Error</p>
        <p class="toast__message">${str}</p>
      </div>
      <div class="progress active" style="--bg: #EC1558"></div>
    </div>`;

    const info = `<div class="toast-content">
      <div class="close">&#10005;</div>
      <div class="icon" style="--stroke:#1D90FF">
        <svg aria-hidden="true" focusable="false"  xmlns="http://www.w3.org/2000/svg" width="1.875em" height="1.875em" viewBox="0 0 30 30"> <circle fill="none" stroke-width="2" cx="50%" cy="50%" r="13" stroke-dasharray="100"> <animate attributeName="stroke-dashoffset" from="100" to="0" dur="0.9s" /> </circle> <line fill="none" stroke-width="3" stroke-linecap="round" x1="15" y1="9" x2="15" y2="9" stroke-dasharray="100"> <animate attributeName="stroke-dashoffset"  from="100" to="0" dur="6s" /> </line> <line fill="none" stroke-width="3" stroke-linecap="round" x1="15" y1="15" x2="15" y2="22" stroke-dasharray="100"> <animate attributeName="stroke-dashoffset"  from="100" to="0" dur="6s" /> </line> </svg>
        </div>
        <div>
          <p class="toast__type">Info</p>
          <p class="toast__message">${str}</p>
        </div>
        <div class="progress active" style="--bg: #1D90FF"></div>
      </div>
    </div>`;

    const EL = document.createElement("div");
    EL.classList.add("toast", toastType);
    if (toastType === "success") {
      EL.innerHTML = success;
    } else if (toastType === "warning") {
      EL.innerHTML = warning;
    } else if (toastType === "error") {
      EL.innerHTML = danger;
    } else {
      EL.innerHTML = info;
    }
    toastContainer.prepend(EL);

    const closeIcon = document.querySelector(".close");
    // const progress = document.querySelector(".progress")
    closeIcon.addEventListener("click", () => {
      EL.classList.remove("open");
      clearTimeout(
        setTimeout(() => toastContainer.removeChild(EL), duration + FADE_DUR)
      );
      // toastContainer.removeChild(EL)
      clearTimeout(duration);
    });

    setTimeout(() => EL.classList.add("open"));
    setTimeout(() => EL.classList.remove("open"), duration);
    setTimeout(() => toastContainer.removeChild(EL), duration + FADE_DUR);
  }
};

export default Notification;
